.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.comicBubble:after {
    content: "" !important;
    position: absolute !important;
    border-style: solid !important;
    /* reduce the damage in FF3.0 */
    display: block !important;
    width: 0 !important;
    top: -15px !important;
    right: 50px !important;
    bottom: auto !important;
    left: auto !important;
    border-width: 0 15px 15px !important;
    border-color: #fefefe transparent !important;
}


.triangle-isosceles {
  position:relative;
  padding:15px 15px 0 15px;
  margin:.05em 0 .05em;
  color:#000;
  background:#fefefe; /* default background for browsers without gradient support */
  /* css3 */
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  border-radius:10px;
}

.triangle-isosceles:after {
  content:"";
  position:absolute;
  border-style:solid;
  /* reduce the damage in FF3.0 */
  display:block;
  width:0;
  top:-15px; /* value = - border-top-width - border-bottom-width */
  left:10px; /* controls horizontal position */
  bottom:auto;
  right:auto;
  border-width:0 15px 15px; /* vary these values to change the angle of the vertex */
  border-color:#f6f6f6 transparent;
}
